import { Button, HorizontalLine, Loader, ContainerColumn, Bold } from '../utils.style';
import { QrcodeContainer, Qrcode, CircleInfo, CircleText } from './QrcodeBox.style';

import defaultQrcode from '../../assets/qrcode.svg';
import loading from '../../assets/loading.svg';
import arrowDown from '../../assets/arrow-down.svg';
import checked from '../../assets/checked.svg';
import getServer from '../../utils/getServer';
import pink from '../../API/pink';
import React, { useState, useEffect } from 'react';

export default function QrcodeBox({ isConnected, infos, setIsConnected }) {
    const [isLoading, setIsLoading] = useState(false);
    const [qrcodeImg, setQrCodeImg] = useState(defaultQrcode);
    const [isVisible, setIsVisible] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const { phoneNumber, url, userInfos, phoneInfos, userId, token } = infos;
    const workspaceName = phoneInfos.workspace;
    const server = getServer(url);

    const [retrieveHistory, setRetrieveHistory] = useState(false);

    useEffect(() => {
    }, [retrieveHistory]);

    const handleCheckboxChange = async(event) => {
        setRetrieveHistory(event.target.checked);

        let history = event.target.checked;

        await pink.updateHistoryFlag(phoneNumber, server, userId, token, history);
    };

    const [historyVisible, setHistoryVisible] = useState(false);

    useEffect(() => {
    }, [historyVisible]);


    const checkHistoryWorkspace = async () => {
        const { response: isHistoryWorkspace } = await pink.getHistoryParam(workspaceName);
        setHistoryVisible(isHistoryWorkspace);
    };

    const updateHistoryToFalse = async () => {
        await pink.updateHistoryFlag(phoneNumber, server, userId, token, false);
    };

    checkHistoryWorkspace();

    if (isFirstLoad) {
        updateHistoryToFalse();
        setIsFirstLoad(false);
    }

    const updateQrcode = () => {
        let isRunning = false
        const trigger = setInterval(async () => {
            if (!isRunning) {
                isRunning = true; //--to avoid concurrency
                const qrcode = await pink.getQrcode(phoneNumber, server, userId, token, retrieveHistory);

                if (!qrcode) {
                    alert("Error to generate QRCode")
                    setIsLoading(false);
                    setIsConnected(false);
                    clearInterval(trigger);
                    return;
                }

                if (qrcode.data === "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6CAYAAACI7Fo9AAAABmJLR0QA/wD/AP+gvaeTAAAEs0lEQVR4nO3dS24bMRBAwVGg+1/ZOUC0oBHG7PBVrQ2Pfg/cNJqv53m+Hv7w9XXmY3m9Xlv/3+r7WH3u7s9l93N3f363+HX6BQD/ntAhQOgQIHQIEDoECB0ChA4BQocAoUPAe/UPT02K7XZqcurUBNju93tqgm636a9v1er34USHAKFDgNAhQOgQIHQIEDoECB0ChA4BQoeA5cm4Vacmz26ZdNpt+q616d/bLZ+LEx0ChA4BQocAoUOA0CFA6BAgdAgQOgQIHQK2T8bxf5p+e+z0CbrpnOgQIHQIEDoECB0ChA4BQocAoUOA0CFA6BBgMu4vndopdkrt/d7CiQ4BQocAoUOA0CFA6BAgdAgQOgQIHQKEDgHbJ+Nqu72m31a6avrrO+WW37MTHQKEDgFChwChQ4DQIUDoECB0CBA6BAgdApYn40xOfbb7NlD/72fUfs9OdAgQOgQIHQKEDgFChwChQ4DQIUDoECB0CHjfshOr5tRk1/Tfy/TXd4oTHQKEDgFChwChQ4DQIUDoECB0CBA6BAgdArbfprp7Ymv3pNMtu8JO7WTbvTNu93NXndqRd4oTHQKEDgFChwChQ4DQIUDoECB0CBA6BAgdArbfpnpqImr1udNv+dztlgmwU9/b9N/z6v9zokOA0CFA6BAgdAgQOgQIHQKEDgFChwChQ8D7lom33c895ZYJsFvcMjHoRIcAoUOA0CFA6BAgdAgQOgQIHQKEDgFCh4Bjt6maAPts+iTW9MnC3abfWrvKiQ4BQocAoUOA0CFA6BAgdAgQOgQIHQKEDgHv6bdtTt8Zd8vtrNMnEKdPDK461ZsTHQKEDgFChwChQ4DQIUDoECB0CBA6BAgdApZ3xk3f8Tb9dtbd7+PUpN30SUXP/cyJDgFChwChQ4DQIUDoECB0CBA6BAgdAoQOAcuTcbXJs1NOTQJOvw10+i7C3dymCnyb0CFA6BAgdAgQOgQIHQKEDgFChwChQ8DreZ6lEaHpO+NOmX7L56kJutqk4m5uUwW+TegQIHQIEDoECB0ChA4BQocAoUOA0CHg9TV99GzR9Im82uurmb4r0YkOAUKHAKFDgNAhQOgQIHQIEDoECB0ChA4By7epTjd9t9cpt+x4m/79Tp98dKJDgNAhQOgQIHQIEDoECB0ChA4BQocAoUPAe/pk1ym7J5Omr+a7ZYLu1O2203f4OdEhQOgQIHQIEDoECB0ChA4BQocAoUOA0CHg9TzP0kjP9MmuVacmp1ZN3z22m+/jZzjRIUDoECB0CBA6BAgdAoQOAUKHAKFDgNAhYPttqrXbNne75X3sNn234e5derv/nxMdAoQOAUKHAKFDgNAhQOgQIHQIEDoECB0Ctk/G1ZzaeXbLpNipz+WW565yokOA0CFA6BAgdAgQOgQIHQKEDgFChwChQ4DJuGGm79y7ZSLv1Ps4dTurEx0ChA4BQocAoUOA0CFA6BAgdAgQOgQIHQK2T8bVbgOdfovmbnbffXZq4m2VEx0ChA4BQocAoUOA0CFA6BAgdAgQOgQIHQJez/O0RtkWnZrwmz4pturUxOCq6bef7v4dONEhQOgQIHQIEDoECB0ChA4BQocAoUOA0CHgN0XKXSXlZy7+AAAAAElFTkSuQmCC") {
                    setIsVisible(true);
                    setIsConnected(true);
                    setQrCodeImg(defaultQrcode);
                    clearInterval(trigger);
                } else if (qrcode.data === "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEAAQMAAABmvDolAAAABlBMVEX///8AAABVwtN+AAABGUlEQVR42uyYvXHFIBCEj3kBISVQCqVJpakUlUCogNF63vFjCRs5tMfsRqD5opu70y5CURT11+WQtYm4Q8SU60GgB/Jx2cIuNonB9etUgAUgy5avBrIAkcATkDuKwA9AQJTXSWAMlNkUX4DR8M4O1GUedne8geG2nxy4yuJ8tgr/GHCHTUZ3lAdSaS4XCfRArh/WAET967UjgRvgdMqKn3ydWkl/n00CuZAKrNKM93pf5gQ+ZzM782YPNh8JfAGaAoBkTu0+h+mAa6RFMnjbJB8H2X9q4OInLdQfdMucQJdQdrGlkveWI9BlXh/r48DuvqskgZp50ezBIBRPDtTZrK8ouvfnA1qk1UI9Zf+5AYqiqN/RRwAAAP//Jb3m841Dr34AAAAASUVORK5CYII=") {
                    setIsVisible(true);
                    setIsConnected(true);
                    setQrCodeImg(defaultQrcode);
                    clearInterval(trigger);
                } else {
                    setIsConnected(false);
                    setQrCodeImg(qrcode.data);
                    setIsLoading(false);
                }

                isRunning = false;
            }
        }, 5000);
    }

    const generateQrcode = async () => {
        setIsLoading(true);
        setIsVisible(false);
        await pink.resetSession(phoneNumber, server, userId, token);
        updateQrcode();
    }

    const resetSession = async () => {
        setIsLoading(true);
        await pink.resetSession(phoneNumber, server, userId, token);
        updateQrcode();
        setIsVisible(false);
    }

    return (
        <QrcodeContainer>
            <ContainerColumn width={'300px'} className='alignCenter'>
                <Qrcode isConnected={isConnected}>
                    {isLoading && <Loader src={loading} />}
                    <img src={qrcodeImg} alt='qrcode' />
                    <CircleInfo isConnected={isConnected} isVisible={isVisible}>
                        {!isConnected ?
                            <>
                                <CircleText>
                                    <Bold fontSize={12} fontWeight={900}>CLIQUE NO BOTÃO ABAIXO </Bold>PARA RECARREGAR <br />O QR CODE
                                </CircleText>
                                <img src={arrowDown} alt='Circle Info' />
                            </>
                            :
                            <img src={checked} alt='Circle info' />
                        }

                    </CircleInfo>
                </Qrcode>

                {!isConnected && historyVisible ?
                    <>

                        <label class="container">
                            <input type="checkbox" onChange={handleCheckboxChange} />
                            <span class="checkmark"></span>
                            <p class="checkmark-text">Importar histórico do WhatsApp</p>
                        </label>
                    </>
                    :
                    <></>
                }

                {userInfos.roles?.includes('admin') ?
                    <ContainerColumn width={'inherit'}>
                        {!isConnected &&
                            <ContainerColumn marginTop={20} justifyContent={'space-between'}>
                                <Button onClick={generateQrcode}>Gerar novo Qr Code</Button>
                                <Button isOutlined marginTop={'8px'} onClick={resetSession}>Resetar Sessão</Button>
                            </ContainerColumn>
                        }
                    </ContainerColumn>
                    :
                    <>
                        {
                            !isConnected && 
                            <ContainerColumn marginTop={20} justifyContent={'space-between'}>
                                <Button onClick={generateQrcode}>Gerar novo Qr Code</Button>
                            </ContainerColumn>
                        }
                    </>
                }
            </ContainerColumn>
            <HorizontalLine />
        </QrcodeContainer>
    );
}
