import { ContainerColumn, ContainerRow, Text, Bold } from '../utils.style'
import { VerticalLine } from './Steps.style'

export default function Steps() {
    return (
        <ContainerRow marginTop={20} marginBottom={29}>
            <VerticalLine />    
            <ContainerColumn>
                <Text className='marginBottomText'>1. Clique no botão <Bold>Gerar novo Qr Code</Bold>;</Text>
                <Text className='marginBottomText'>2. Aponte a <Bold>câmera do Whatsapp</Bold> da empresa para o código ao lado até escaneá-lo</Text>
                <Text className='marginBottomText'>Se após clicar em "Gerar novo QR Code" a imagem do código não aparecer ou surgir alguma mensagem de erro, por favor, repita o processo e aguarde um instante.</Text>
            </ContainerColumn>
        </ContainerRow>
    )
}