import axios from 'axios';

const pink = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,  
});

pink.getHistoryParam = async (workspace) => {
    try {
        console.log(`Calling /historyParam`);
        const response = await pink.post('/historyParam', {workspace });
        return response.data
    } catch(error) {
        console.error('Error trying to get historyParam', error);
    }
}

pink.getPhoneInfos = async (phoneNumber, server, userId, authToken) => {
    try {
        console.log(`Calling /phoneInfos`);
        const response = await pink.post('/phoneInfos', { phoneNumber, server, userId, authToken });
        return response.data
    } catch(error) {
        console.error('Error trying to get phoneInfos', error);
    }
}

pink.authenticateUser = async (userId, token, rocketchatUrl, phoneNumber) => {
    try {
        console.log(`Calling /authenticateUser`)
        const response = await pink.post('/authenticateUser', { userId, token, rocketchatUrl, phoneNumber });
        return response.data;
    } catch (error) {
        console.error('Error trying to authenticate user', error);
    }
}

pink.resetSession = async (phoneNumber, server, userId, authToken) => {
    try {
        console.log(`Calling /resetSession`)
        const response = await pink.post('/resetSession', { phoneNumber, server, userId, authToken });
        return response.data
    } catch(error) {
        console.error(`Error trying to reset session from number ${phoneNumber}`);
    }
}

pink.updateHistoryFlag = async (phoneNumber, server, userId, authToken, history) => {
    try {
        console.log(`Calling /history`)
        const response = await pink.put('/history', { phoneNumber, server, userId, authToken, history });
        return response.data
    } catch(error) {
        console.error(`Error trying to update history for number ${phoneNumber}`);
    }
}

pink.getQrcode = async (phoneNumber, server, userId, authToken, retrieveHistory) => {
    try {
        console.log(`Calling /getQrcode`, retrieveHistory)
        const response = await pink.post('/getQrcode', { phoneNumber, server, userId, authToken, retrieveHistory });
        const { data } = response || {}
        return data
    } catch(error) {
        console.error(`Error trying to get qrcode from number ${phoneNumber}`);
    }
}

pink.getUserInfo = async (userId, server) => {
    try {
        console.log(`Calling /getUserInfo`)
        const response = await pink.post('/getUserInfo', { userId, server });
        return response.data
    } catch (error) { 
        console.log(`Error trying to get infos about userId ${userId} on server ${server}`);
    }
}

export default pink;