import styled from "styled-components";

export const QrcodeContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 416px;

    @media (max-width: 920px) {
        margin-top: 32px;
    }

    font-family: Nunito,sans-serif;
`

export const Qrcode = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    border: ${props => props.isConnected ? '1px solid rgba(28, 202, 177)' : null};
    outline: ${props => props.isConnected ? '4px solid rgba(28, 202, 177, 0.2)' : null};
    border-radius: 4px;
    padding: 4px;
    font-family: Nunito,sans-serif;
`

export const CircleInfo = styled.div`
    position: absolute;

    width: ${props => props.isConnected ? '80px' : '184px'};
    height: ${props => props.isConnected ? '80px' : '184px'};
    border-radius: 50%;

    background: ${props => props.isConnected ? '#1CCAB1' : '#D5156A'};

    display: ${props => props.isVisible ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const CircleText = styled.div`
    width: 113px;
    height: 72px;

    text-align: center;

    font-family: Nunito,sans-serif;
    font-size: 12px;
    line-height: 1.5;
    color: #FFF;
`