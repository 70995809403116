import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GlobalStyles } from './GlobalStyles';
import Qrcode from './Qrcode';

function App() {
  return (
    <Router>
      <GlobalStyles />
      
      <Routes>
        <Route path='/qr' element={<Qrcode />}/>
      </Routes>
    </Router>
  );
}

export default App;
